import {RouteRecordRaw} from 'vue-router';
import {Layout} from '@/router/constant';
import {DeploymentUnitOutlined} from '@vicons/antd';
import {renderIcon} from '@/utils/index';
const routeName = 'admin';

/**
 * 管理员
 */

const routes: Array<RouteRecordRaw> = [
    {
        path: '/admin',
        name: routeName,
        redirect: '/admin/user',
        component: Layout,
        meta: {
            title: '系统设置',
            icon: renderIcon(DeploymentUnitOutlined),
            sort: 1,
            permissions: ['admin', 'administrator']
        },
        children: [
            {
                path: 'shop',
                name: `${routeName}_shop`,
                meta: {
                    title: '代理商管理',
                },
                component: () => import('@/views/admin/shop/index.vue')
            },
            {
                path: 'client',
                name: `${routeName}_client`,
                meta: {
                    title: '客户管理',
                },
                component: () => import('@/views/admin/client/index.vue')
            },
            /*{
                path: 'package',
                name: `${routeName}_package`,
                meta: {
                    title: '套餐管理',
                },
                component: () => import('@/views/admin/package/index.vue')
            },*/
            {
                path: 'user',
                name: `${routeName}_user`,
                meta: {
                    title: '用户管理',
                    permissions: ['administrator']
                },
                component: () => import('@/views/admin/user/index.vue')
            }
        ]
    }
]

export default routes;
