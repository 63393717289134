import {RouteRecordRaw} from 'vue-router';
import {Layout} from '@/router/constant';
import {UserOutlined} from '@vicons/antd';
import {renderIcon} from '@/utils/index';
const routeName = 'shop';

/**
 * 代理商管理页面
 */
const routes: Array<RouteRecordRaw> = [
    {
        path: '/shop',
        name: routeName,
        redirect: '/shop/user',
        component: Layout,
        meta: {
            title: '系统管理',
            icon: renderIcon(UserOutlined),
            sort: 0,
            permissions: ['admin', 'shop']
        },
        children: [
            {
                path: 'notice',
                name: `${routeName}_notice`,
                meta: {
                    title: '公告管理',
                    sort: 0,
                    isRoot: true,
                },
                component: () => import('@/views/dls/notice/index.vue'),
            },
            {
                path: 'code',
                name: `${routeName}_code`,
                meta: {
                    title: '授权码管理',
                },
                component: () => import('@/views/dls/code/index.vue'),
            },
            {
                path: 'user',
                name: `${routeName}_user`,
                meta: {
                    title: '客户管理',
                },
                component: () => import('@/views/dls/user/index.vue'),
            },
            {
                path: 'package',
                name: `${routeName}_package`,
                meta: {
                    title: '套餐管理',
                },
                component: () => import('@/views/admin/package/index.vue')
            },
            {
                path: 'task',
                name: `${routeName}_task`,
                meta: {
                    title: '任务管理',
                },
                component: () => import('@/views/dls/task/index.vue')
            },
            {
                path: 'config',
                name: `${routeName}_config`,
                meta: {
                    title: '系统配置',
                },
                component: () => import('@/views/dls/config/index.vue')
            },
        ],
    }
]

export default routes;
