import {RouteRecordRaw} from 'vue-router';
import {Layout} from '@/router/constant';
import {CheckCircleOutlined} from '@vicons/antd';
import {renderIcon} from '@/utils/index';

/**
 * @param name 路由名称, 必须设置,且不能重名
 * @param meta 路由元信息（路由附带扩展信息）
 * @param redirect 重定向地址, 访问这个路由时,自定进行重定向
 * @param meta.disabled 禁用整个菜单
 * @param meta.title 菜单名称
 * @param meta.icon 菜单图标
 * @param meta.keepAlive 缓存该路由
 * @param meta.sort 排序越小越排前
 *
 * */
const routes: Array<RouteRecordRaw> = [
    {
        path: '/result',
        name: 'Result',
        redirect: '/result/success',
        component: Layout,
        meta: {
            title: '结果页面',
            icon: renderIcon(CheckCircleOutlined),
            sort: 4,
            permissions: ['development']
        },
        children: [
            {
                path: 'success',
                name: 'result-success',
                meta: {
                    title: '成功页',
                },
                component: () => import('@/views/result/success.vue'),
            },
            {
                path: 'fail',
                name: 'result-fail',
                meta: {
                    title: '失败页',
                },
                component: () => import('@/views/result/fail.vue'),
            },
            {
                path: 'info',
                name: 'result-info',
                meta: {
                    title: '信息页',
                },
                component: () => import('@/views/result/info.vue'),
            },
        ],
    },
];

export default routes;
